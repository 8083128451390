import './App.css';
import Content from "./component/content.js";

/**
 * @author: renlishisan
 * @method: App.js
 * @description:
 * @param:
 * @return:
 * @time: 2021/1/4 11:46 下午
 **/
function App () {
	return (
		<div className='main'>
			<h1/>
			<Content/>
		</div>
	);
}

export default App;
