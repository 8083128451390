import React from 'react'
import qs from 'qs'
import './content.css'
import axios from 'axios'
import Base64 from 'base-64';
import Alert from './Alert'

/**
 * @author: RenLishisan
 * @method: content.js
 * @description: 该页面为信息录入&下方字体提示组件，通过switch方法根据tate的状态return出相对应的DOM元素。
 * @param:
 * @return:
 * @time: 2021/1/4 11:45 下午
 **/
class Content extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			//state三个状态：notRegister未登录、sentRegister已登录、getSucceed领取成功
			tate: 'notRegister',//默认未登录状态
			uuid: '', //服务器返回的用户唯一id
			sid: 'null', //用户选择的服务器id
			serverName: '请选择服务器！',//服务器名字
			username: '', //账号
			password: '',  //密码
			userid: '角色不存在!', //角色名字
			level: '当前角色等级未达到30级',
			userlevel: 'null', //角色等级
			data: '', //游戏跳转传过来的数据
			get: 'no',
			// 错误提示
			message: ''
		}
	}

	//用户选择的服务器


	//页面加载完获取通过Url从游戏传入过来的数据，并且解码进data。
	componentDidMount () {
		const data = 'data'
		let reg = new RegExp("(^|&)" + data + "=([^&]*)(&|$)", "i");
		let r = window.location.search.substr(1).match(reg);
		if (r !== null) {
			let result = Base64.decode(decodeURIComponent(r[2]))
			let arr = JSON.parse(result)
			this.setState({
				data: arr
			})
		}
	}

	getServer = function () {
		const {uuid, sid,} = this.state
		// let sid = event.target.value
		const api = 'https://toolsapi.moefantasy.com/';


		axios.get(api, {
			params: {
				uuid,
				sid
			}
		}).then(function (response) {
			if (response.data.error === 0) {
				this.setState({
					userid: response.data.data.username,
					userlevel: response.data.data.level
				});
				if (response.data.data.level <= 30) {
					this.setState({
						level: '当前角色等级未达到30级'
					})
				} else {
					this.setState({
						level: '当前角色等级已达到30级'
					})
				}
			} else {
				this.setState({
					userlevel: 'null',
					userid: '角色不存在！',
					level: '当前角色等级未达到30级'
				})
			}
		}.bind(this))
	}

	/**
	 * @author: renlishisan
	 * @method: Register
	 * @description: 更改玩家进入登录状态
	 * @param:
	 * @return:
	 * @time: 2021/1/5 5:29 下午
	 **/
	Register = () => {
		const {username, password, sid} = this.state;
		const api = 'https://toolsapi.moefantasy.com/jrlink/uuid';
		this.setState({ message: '' })
		axios.post(api, qs.stringify({
			username,
			password,
			sid
		}), {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then(function (response) {
			switch (response.data.error){
				case 0:
					this.setState({
						tate: 'sentRegister',
						uuid: response.data.data.uuid,
						userid:response.data.data.username,
						userlevel:response.data.data.level,
					})
					break;
				case 20001:
					this.setState({ message: '用户名或密码不正确!' })
					break;
				case 60001:
					this.setState({ message: '提示：'+ response.data.msg})
					break;
				case 60004:
					this.setState({ message: '请选择区服!'})
					break;
			}
		}.bind(this))
		switch (sid) {
			case 'null':
				this.setState({
					serverName: '请选择服务器!'
				})
				break;
			case '2':
				this.setState({
					serverName: '胡德'
				})
				break;
			case '4':
				this.setState({
					serverName: '俾斯麦'
				})
				break;
			case '13':
				this.setState({
					serverName: '昆西'
				})
				break;
			case '14':
				this.setState({
					serverName: '长春'
				})
				break;
			case '101':
				this.setState({
					serverName: '列克星敦'
				})
				break;
			case '108':
				this.setState({
					serverName: '维内托'
				})
				break;
		}
	}
	/**
	 * @author: renlishisan
	 * @method: notRegister
	 * @description: 点击取消按钮
	 * @param:
	 * @return:
	 * @time: 2021/1/5 6:09 下午
	 **/
	Cancel = () => {
		this.setState({
			tate: 'notRegister',//默认未登录状态
			uuid: '', //服务器返回的用户唯一id
			sid: 'null', //用户选择的服务器id
			serverName: '请选择服务器！',//服务器名字
			userid: '角色不存在!', //角色名字
			level: '当前角色等级未达到30级',
			userlevel: 'null', //角色等级
			get: 'no'
		})
	}
	/**
	 * @author: renlishisan
	 * @method: getSucceed
	 * @description: 点击确定按钮领取奖励
	 * @param:
	 * @return:
	 * @time: 2021/1/5 6:10 下午
	 **/
	Confirm = () => {
		const api = 'https://toolsapi.moefantasy.com/jrlink/confirm';
		const {uuid, data} = this.state
		const jr_id = uuid;
		const uid = data.uid;
		const clover_id = data.acount_guid;
		const channel_id = data.channel_id;
		const service_id = data.service_id;
		this.setState({ message: '' })
		if (this.state.userlevel >= 30) {
			axios.post(api, qs.stringify({
				uid, jr_id, clover_id, channel_id, service_id
			}), {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}).then((response) => {
				switch (response.data.error) {
					case 0:
						this.setState({
							//领取成功更改tate状态
							tate: 'getSucceed',
						})
						break;

					case 60002:
						this.setState({ message: '团长大人您已经成功领取过礼物啦!'})
						break;
					case 60003:
						this.setState({ message: '错误提示：数据请求出错，请重新从游戏内打开!' })
						break;
					case 20001:
						this.setState({ message: '错误提示：请团长大人通过游戏打开链接哦!'})
						break;
				}
			})
		} else {
			this.setState({ message: '玩家等级尚未达到30级' })
		}
	}
	//获取输入账号
	getName = (event) => {
		this.setState({
			username: event.target.value,
		})
	}
	//获取输入密码
	getPassword = (event) => {
		this.setState({
			password: event.target.value,
		})
	}
	//获取选择的服务器
	getSid = function (event) {
		this.setState({
			sid: event.target.value
		})
	}.bind(this)

	render () {
		switch (this.state.tate) {
			/**
			 * @author: renlishisan
			 * @method: notRegister
			 * @description: 未登录状态，校验用户名。
			 * @param:
			 * @return:
			 * @time: 2021/1/5 5:35 下午
			 **/
			case "notRegister":
				return (
					<div>
						<Alert message={this.state.message} />
						<div className='message'>
							<div className='account'>
								<span>战舰少女R <br/>&nbsp;&nbsp;&nbsp;&nbsp;账号</span>
								&nbsp;&nbsp;&nbsp;
								<input defaultValue={this.state.username} onChange={this.getName} className='account-text'
									   type="text"/>
							</div>
							<div>
								<span
									className='account-password'>&nbsp;&nbsp;&nbsp;&nbsp;密码&nbsp;&nbsp;&nbsp;&nbsp;</span>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<input defaultValue={this.state.password} onChange={this.getPassword}
									   className='account-text' type='password'/>
							</div>
							<div>
								<span
									className='account-password'>&nbsp;&nbsp;&nbsp;&nbsp;区服&nbsp;&nbsp;&nbsp;&nbsp;</span>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<select className='account-text' onChange={this.getSid}>
									<option value="null">请选择服务器</option>
									<option value="2">胡德</option>
									<option value="4">俾斯麦</option>
									<option value="13">昆西</option>
									<option value="14">长春</option>
									<option value="101">列克星敦</option>
									<option value="108">维内托</option>
								</select>
							</div>
							<button className='account-button' onClick={this.Register}/>
						</div>
						<ol className='card'>
							<br/>
							<li>领取奖励需要战舰少女R帐号等级≥30级</li>
							<li>一个四叶草剧场帐号只能绑定一个战舰少女R帐号, 领取一次奖励</li>
							<li>填写成功后，请在邮箱查收奖励，奖励发放会有延迟，请团长大人耐心等待</li>
							<br/>
						</ol>
					</div>
				)
			/**
			 * @author: renlishisan
			 * @method: sentRegister
			 * @description: 登录状态 选择服务器
			 * @param:
			 * @return:
			 * @time: 2021/1/5 5:36 下午
			 **/
			case 'sentRegister':

				return (

					<div>
						<Alert message={this.state.message}/>
						<div className='message'>
							<div className='account-top account-sentRegister'>
								<span>区服 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
								&nbsp;
								<span className='account-text text-font'>{this.state.serverName}</span>
							</div>
							<div className='account-sentRegister'>
								<span>角色名&nbsp;&nbsp;</span>
								&nbsp;
								<span className='account-text text-font'>{this.state.userid}</span>
							</div>
							{
								this.state.userlevel === 'null' ?
									<span className='sentRegister-span'>请选择服务器</span> :
									this.state.userlevel >= 30 ?
										<span className='sentRegister-span'
											  style={{color: "#78a25f"}}>当前角色等级：{this.state.userlevel}</span> :
										<span className='sentRegister-span'>角色等级未到30级</span>
							}
							<div className='sentRegister-button'>
								<button className='cancel' onClick={this.Cancel}/>
								<button className='confirm' onClick={this.Confirm}/>
							</div>
						</div>
						<ol className='card card2'>
							<span>点击确定领取奖励~</span>
						</ol>
					</div>
				)
			/**
			 * @author: renlishisan
			 * @method: getSucceed
			 * @description: 奖励领取成功状态
			 * @param:
			 * @return:
			 * @time: 2021/1/5 5:36 下午
			 **/
			case 'getSucceed':
				return (
					<div>
						<div className='getSucceed'>
						</div>
						<div className='card card2'>
							邮件有效期：30天，还请团长大人尽快领取哦！
						</div>
					</div>
				)

		}

	}
}

export default Content
