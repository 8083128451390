import React, { useState, useEffect, Fragment  } from 'react'
import "./alert.css"

const Alert = (props) => {
  const { message } = props

  let timeout

  const [visible, setVisible] = useState(false)
  const [tip, setTip] = useState(message)

  useEffect(() => {
    if (message) {
      setVisible(true)
      setTip(message)
    }
  }, [message])

  useEffect(() => {
    if (visible) {
      // 创建定时任务关闭弹窗
      timeout = setTimeout(function () {
        setVisible(false)
        setTip('')
      }, 1000);
    } else {
      // 删除定时任务
      clearTimeout(timeout)
    }
  }, [visible])

  const handleClose = (event) => {
    const target = event.target;
    // if (target.className !== 'alert-dialog-message') {
    //   setVisible(false)
    // }
  }

  useEffect(() => {
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Fragment>
      {visible &&　(
        <div className="alert-dialog" onClick={handleClose}>
          <div className="alert-dialog-container">
            <div className="alert-dialog-message">{tip}</div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default Alert
